<template>
  <div class="vm-modal blikPayLater">
    <h2 class="normal" v-html="$t('configuration.paywayGroups.blikPayLater.modal.header1')"></h2>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.blikPayLater.modal.header2')"></h4>
    <p v-html="$t('configuration.paywayGroups.blikPayLater.modal.text2')"></p>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.blikPayLater.modal.header3')"></h4>
    <p v-html="$t('configuration.paywayGroups.blikPayLater.modal.text3')"></p>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.blikPayLater.modal.header4')"></h4>
    <p v-html="$t('configuration.paywayGroups.blikPayLater.modal.text4')"></p>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.blikPayLater.modal.header5')"></h4>
    <div class="blikPayLaterBanks">
      <img src="/web/img/icons/blikPayLaterMillenium.png" alt="Millenium Bank">
      <img src="/web/img/icons/blikPayLaterVelo.png" alt="Velo Bank">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlikPayLaterModal'
}
</script>
<style lang="scss" scoped>
.vm-modal {
  h4 {
    color: #3C3C46;
    margin-top: 20px;
  }

  p {
    margin-top: 10px;
  }

  .blikPayLaterBanks {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

}
</style>
